<template lang="pug">
  .organization-plan
    fp-loader(v-if="pageLoading",type="page")
    .organization-plan-container(v-else)
      fp-loader(v-if="loading",type="page")
      fp-title(
        :border="false"
        :parent="$t('organization.plan')"
      )
        .actions-buttons(v-if="subscription && !isActiveSubscription")
          fpui-button(
            :auto-width="true"
            color='blue-flash'
            icon='fp4 fp4-plus'
            icon-left
            @click="reactivateSubscription"
          ) {{ $t('organization.plan.reactivate') }}

      .organization-plan-content(v-if="!subscription || activeTrial")
        no-subscription(
          :organization-id="organizationId"
          :upgrade-plan="activeTrial"
        )
      .organization-plan-content(v-else)
        fpui-tabs(
          ref="tabs"
          @change="val => updateRoute(val)"
        )
          fpui-tab(:header="$t('organization.plan.overview')")
            overview(:organization-id="organizationId")
          fpui-tab(:header="$t('organization.plan.billing')")
            billing(:organization-id="organizationId")
          fpui-tab(
            :header="$t('organization.plan.receipts')"
            v-if="isAdmin"
          )
            receipts(
              :id="organizationId",
              type="organization"
            )
</template>

<script>
import Receipts from '@/core/components/Account/Receipts'
import NoSubscription from '@/core/components/Home/NoSubscription'
import Overview from './Overview'
import Billing from './Billing/Billing'
import SubscriptionNew from '@/core/components/Subscription/New.vue'
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  components: {
    Overview, Billing, Receipts, NoSubscription
  },
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      pageLoading: true,
      loading: false
    }
  },
  computed: {
    isActiveSubscription () {
      return ['future', 'in_trial', 'active', 'paused'].includes(this.organization?.subscription?.status)
    },
    activeTrial () {
      return this.organization?.subscription?.status === 'in_trial' && this.organization?.subscription?.scheduled_status === 'cancelled'
    },
    account () {
      if (!this.organization.customerAccountId) return null
      return this.$store.getters.ACCOUNT_BY_ID(this.organization.customerAccountId)
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization?.subscription
    },
    isAdmin () {
      return this.session?.acls === '*'
    },
    session () {
      return this.$store.getters.ORGANIZATION_SESSION
    }
  },
  async mounted () {
    this.$store.commit('SET_OPEN_SIDE_BAR', false)

    this.pageLoading = true
    try {
      await Promise.all([this.organization.loadSubscription(), this.organization.loadConsumptions(), this.organization.loadCommitteds()])
    } catch (err) {
      this.$fpuiMessageBlock.error(err)
    }
    this.pageLoading = false

    const trackFrom = window.localStorage.getItem('tracking-from')
    if (trackFrom) {
      this.$analytics.track('Open Billing Explorer', { from: trackFrom })
      window.localStorage.removeItem('tracking-from')
    }

    await this.$nextTick()
    if (this.$route.query?.tabActive) {
      // If update here, you have to update the method updateRoute
      switch (this.$route.query?.tabActive) {
        case 'overview':
          this.$refs.tabs.setActive(0)
          break
        case 'billing':
          this.$refs.tabs.setActive(1)
          break
        case 'receipts':
          this.$refs.tabs.setActive(2)
          break
      }
    }
  },
  methods: {
    async reactivateSubscription () {
      if (this.organization?.subscription?.status === 'non_renewing') {
        if (await ConfirmModal(this, {
          title: this.$t('onboarding.blocks.scale_reactivate.title'),
          content: this.$t('subscription.reactivate.confirmation'),
          yes: { color: 'green', text: this.$t('home.dataplant.inactive_subscription.reactivate') }
        })) {
          this.loading = true
          try {
            await this.organization.reactivateSubscription()
          } catch (err) {
            this.$fpuiMessageBlock.error(err)
          }
          this.loading = false
        }
      } else {
        this.$modal.show(SubscriptionNew, {
          organizationId: this.organizationId,
          trial: true
        }, {
          height: 'auto',
          width: 900
        })
      }
    },
    updateRoute (val) {
      let step = 'overview'
      switch (val.active) {
        case 0:
          step = 'overview'
          break
        case 1:
          step = 'billing'
          break
        case 2:
          step = 'receipts'
          break
      }

      const query = {
        ...this.$route.query,
        tabActive: step
      }

      this.$router.push({
        path: `/organization/${this.$route.params.organizationId}/plan`,
        query
      })
    }
  }
}
</script>

<style lang="less">
.organization-plan {
  overflow: hidden;
  .organization-plan-content {
    .fpui-tabs-heads {
      background: white;
    }
    background:linear-gradient(0deg, rgba(246, 249, 252, 0) 0%, #F6F9FC 100%) !important;
    .receipts {
      padding: 16px 69px;
    }
  }
}
</style>
