<template lang="pug">
  .multi-factor-authentication
    fp-loader(v-if="loading")
    .title-container(v-if="mfaActive").mfa-active
      .icon
        i.fp4.fp4-lock-keyhole(
            :style="{ color: '#9EDF10' }"
          )
      .title(:style="{ color: '#9EDF10' }") {{ $t('profile.security.mfa.active.title') }}
    .title-container.mfa-non-active(v-if="!mfaActive")
      .title {{ $t('profile.security.mfa') }}
    .text.explanation {{ $t('profile.security.mfa.content') }}
    .mfa-config-section
      .authenticator-app
        .title {{$t('profile.security.mfa.authenticator_app.title')}}
        .text {{$t('profile.security.mfa.authenticator_app.text')}}
        .button-wrapper
          fpui-button.backup-button(
            v-if="!authenticatorEnabled"
            color="blue-flash"
            @click="enableAuthApp('fromAuthAppfromSecurityTab')"
            auto-width
          ) {{ $t('profile.security.mfa.authenticator_app.button.enable_authenticator') }}
          fpui-button(
            v-if="authenticatorEnabled"
            color="white-with-red-text"
            @click="removeAuthApp()"
            auto-width
          )  {{ $t('profile.security.mfa.authenticator_app.button.remove_authenticator') }}
        .last-downloaded-date(v-if="downloadedBackupCodes") {{$t('profile.security.mfa.authenticator_app.last_downloaded_date',[authenticatorLastEnabledDate]) }}
      .sms-backup
        .title {{ $t('profile.security.mfa.sms_backup.title') }}
        .text
          span {{ $t('profile.security.mfa.sms_backup.text', [phoneNumber ? phoneNumber : this.$t('profile.security.mfa.sms_backup.text.phone_not_registered')]) }}
          span.reveal(v-if="user.phone" @click="showPhoneNumber = !showPhoneNumber") {{ showPhoneNumber ? $t('profile.security.mfa.sms_backup.text.hide') : $t('profile.security.mfa.sms_backup.text.reveal')}}
        .button-wrapper
          fpui-button.backup-button(
            v-if="!smsAuthenticationEnabled"
            :disabled="!user.phone"
            color="blue-flash"
            @click="enableSmsAuth('fromSmsInSecurityTab')"
            auto-width
          ) {{ $t('profile.security.mfa.sms_backup.button.enable') }}
          fpui-button(
            v-if="smsAuthenticationEnabled"
            color="white-with-red-text"
            @click="removeSmsAuth()"
            auto-width
          ) {{ $t('profile.security.mfa.sms_backup.button.remove') }}
        .activated-date(v-if="smsAuthenticationEnabled") {{ $t('profile.security.mfa.sms_backup.activated_date', [smsAuthActivatedDate]) }}

</template>
<script>
import TwoFactorAuthModal from './Authentication/TwoFactorAuthModal.vue'
import ConfirmModal from '@/shared/components/modals/Confirm'
import Date from '@/shared/filters/Date'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  components: { TwoFactorAuthModal },
  data () {
    return {
      loading: false,
      showPhoneNumber: false,
      step: 'authenticator-setting' // It will be either 'authenticator-setting', 'backup-code-setting', 'sms-auth-confirmation'
    }
  },
  computed: {
    user () {
      return this.$store.getters.SESSION
    },
    authenticatorLastEnabledDate () {
      return Date(this.user.mfa?.authenticator?.enabled_at).format(this.$t('date.formatter.second.slash'))
    },
    smsAuthActivatedDate () {
      return Date(this.user.mfa?.sms?.enabled_at).format(this.$t('date.formatter.second.slash'))
    },
    mfaActive () {
      return this.user?.mfa?.active
    },
    smsAuthenticationEnabled () {
      return this.user.mfa?.sms?.active
    },
    authenticatorEnabled () {
      return this.user.mfa?.authenticator?.active
    },
    phoneNumber () {
      const phone = this.user.phone
      if (!phone) return ''
      return this.showPhoneNumber ? phone : `******${phone.slice(-4)}`
    },
    downloadedBackupCodes () {
      return this.user.mfa?.backup?.generated_at
    }
  },
  methods: {
    /**
     * Enabling Authenticator App
     * @param {string} from - It will be either 'sms', 'authApp', 'fromAuthAppfromSecurityTab'
     * When the augument is 'fromAuthAppfromSecurityTab', it means that this function is called from the 'enable authenticator app' button on the security tab
     * Otherwise it is called during the MFA flow
    */
    async enableAuthApp (from) {
      this.loading = true
      try {
        const qrCodeInfo = await this.$api.KING.registerMfa('authenticator')
        this.step = 'backup-code-setting'
        this.$modal.show(TwoFactorAuthModal, {
          qrCodeInfo: qrCodeInfo,
          onClick: () => {
            this.$emit('load-orgs')
            this.$fpuiMessageBlock.success(this.$t('profile.security.mfa.modal.enable_mfa.authenticator.success'))
            if (from === 'fromAuthAppfromSecurityTab' && !(this.smsAuthenticationEnabled && this.authenticatorEnabled)) {
              this.step = 'backup-code-setting'
              this.showBackupSettingModal('authApp')
            } else if (from === 'sms') {
              this.step = 'backup-code-setting'
              this.showBackupSettingModal('sms')
            }
          }
        }, {
          width: 600,
          height: 626
        })
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      } finally {
        this.loading = false
      }
    },
    /**
     * Show BackupCodeSettings.vue page
     * @param {string} from - should be either 'authApp' or 'sms'
     */
    async showBackupSettingModal (from) {
      this.$modal.show(TwoFactorAuthModal, {
        stepProps: 'backup-code-setting',
        onClickEnableSmsAuthButton: from === 'sms' ? null : () => this.enableSmsAuth(from),
        onClickEnableAuthAppButton: from === 'sms' ? () => this.enableAuthApp(from) : null,
        onClick: async () => {
          this.showConfirmModal(from)
        }
      }, {
        width: 600,
        height: from === 'sms' ? 444 : 483
      })
    },
    async removeAuthApp () {
      this.showConfirmModalToRemoveAuth('authenticator')
    },
    /**
     * Enabling SMS Authentication
     * @param {string} from - It will be either 'sms', 'authApp', 'fromSmsInSecurityTab'
     * When the augument is 'fromSmsInSecurityTab', it means that this function is called from the 'enable sms authentication' button on the security tab
     * Otherwise it is called during the MFA flow
    */
    // 'sms' 'authApp' 'fromSmsInSecurityTab'
    async enableSmsAuth (from) {
      this.loading = true
      const AuthenticatorAlreadyRegistered = _cloneDeep(this.mfaActive && this.user.mfa?.authenticator?.active)
      try {
        this.step = 'sms-auth-confirmation'
        this.$api.KING.registerMfa('sms') // We do not put await for this because we do not have to wait this call to be made before showing modal
        this.$modal.show(TwoFactorAuthModal, {
          stepProps: this.step,
          onClickGoBack: AuthenticatorAlreadyRegistered && from !== 'fromSmsInSecurityTab'
            ? () => {
                this.step = 'backup-code-setting'
                this.showBackupSettingModal('authApp')
              }
            : null,
          onClick: async () => {
            this.$emit('load-orgs')
            this.$fpuiMessageBlock.success(this.$t('profile.security.mfa.modal.enable_mfa.success'))

            if (from === 'fromSmsInSecurityTab' && !(this.smsAuthenticationEnabled && this.authenticatorEnabled)) {
              this.step = 'backup-code-setting'
              this.$modal.show(TwoFactorAuthModal, {
                stepProps: this.step,
                onClickEnableAuthAppButton: () => this.enableAuthApp('sms'),
                onClickEnableSmsAuthButton: null,
                onClick: async () => {
                  this.showConfirmModal('sms')
                }
              }, {
                width: 600,
                height: 444
              })
            } else if (from === 'authApp') {
              this.step = 'backup-code-setting'
              this.$modal.show(TwoFactorAuthModal, {
                stepProps: this.step,
                onClickEnableAuthAppButton: () => this.enableAuthApp('authApp'),
                onClickEnableSmsAuthButton: null,
                onClick: async () => {
                  this.showConfirmModal('authApp')
                }
              }, {
                width: 600,
                height: 444
              })
            }
          }
        }, {
          width: 600,
          height: 263
        })
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      } finally {
        this.loading = false
      }
    },
    removeSmsAuth () {
      this.showConfirmModalToRemoveAuth('sms')
    },
    // @param {string} mfaMethod - should be either 'authenticator' or 'sms'
    async showConfirmModalToRemoveAuth (mfaMethod) {
      const confirm = await ConfirmModal(this, {
        title: this.$t(`profile.security.mfa.modal.remove_${mfaMethod}_app.title`),
        content: this.$t(`profile.security.mfa.modal.remove_${mfaMethod}_app.content`),
        className: 'confirm-removing-authentication',
        cancel: {
          label: this.$t('profile.security.mfa.modal.confirm_no_backup.button.go_back'),
          color: 'blue-flash'
        },
        yes: {
          label: this.$t('profile.security.mfa.modal.confirm_no_backup.button.sure'),
          color: 'white-with-red-text'
        }
      })
      if (confirm) {
        this.loading = true
        try {
          await this.$api.KING.disableMFA({ type: mfaMethod })
          this.$store.dispatch('CHECK_CMP_SESSION')
          this.$emit('load-orgs')
          this.$fpuiMessageBlock.success(this.$t(`profile.security.mfa.modal.disable_${mfaMethod}_mfa.success`))
        } catch (err) {
          this.$fpuiMessageBlock.pop('error', err.message)
        } finally {
          this.loading = false
        }
        this.$emit('close')
      } else {
        this.$emit('close')
      }
    },
    // @param {string} from - it should be either 'authApp' or 'sms'
    async showConfirmModal (from) {
      let content
      let title
      if (from === 'authApp') {
        if (!this.smsAuthenticationEnabled && !this.downloadedBackupCodes) {
          content = 'profile.security.mfa.modal.confirm_no_backup_and_sms_auth.content'
          title = 'profile.security.mfa.modal.confirm_no_backup_and_sms_auth.title'
        } else if (!this.smsAuthenticationEnabled && this.downloadedBackupCodes) {
          content = 'profile.security.mfa.modal.confirm_no_sms_auth.content'
          title = 'profile.security.mfa.modal.confirm_no_sms_auth.title'
        } else if (this.smsAuthenticationEnabled && !this.downloadedBackupCodes) {
          content = 'profile.security.mfa.modal.confirm_no_backup.content'
          title = 'profile.security.mfa.modal.confirm_no_backup.title'
        }
      } else {
        if (!this.authenticatorEnabled && !this.downloadedBackupCodes) {
          content = 'profile.security.mfa.modal.confirm_no_backup_and_authenticator.content'
          title = 'profile.security.mfa.modal.confirm_no_backup_and_authenticator.title'
        } else if (!this.authenticatorEnabled && this.downloadedBackupCodes) {
          content = 'profile.security.mfa.modal.confirm_no_authenticator.content'
          title = 'profile.security.mfa.modal.confirm_no_authenticator.title'
        } else if (this.authenticatorEnabled && !this.downloadedBackupCodes) {
          content = 'profile.security.mfa.modal.confirm_no_backup.content'
          title = 'profile.security.mfa.modal.confirm_no_backup.title'
        }
      }
      const confirm = await ConfirmModal(this, {
        contentHtml: true,
        title: this.$t(title),
        content: this.$t(content),
        className: 'confirm-without-backup-modal',
        cancel: {
          label: this.$t('profile.security.mfa.modal.confirm_no_backup.button.go_back'),
          color: 'blue-flash'
        },
        yes: {
          label: this.$t('profile.security.mfa.modal.confirm_no_backup.button.sure'),
          color: 'white-with-red-text'
        }
      })
      if (confirm) {
        if (this.$route.query?.mfa_redirect) {
          const targetOrganization = this.$store.getters.ORGANIZATION_BY_ID(this.$route.query?.mfa_redirect)
          if (targetOrganization) {
            const confirmMfaRedirect = await ConfirmModal(this, {
              contentHtml: true,
              title: this.$t('profile.security.mfa.redirect.title'),
              content: this.$t('profile.security.mfa.redirect.content', [targetOrganization?.name]),
              cancel: {
                label: this.$t('no')
              },
              yes: {
                label: this.$t('profile.security.mfa.redirect.button'),
                color: 'blue-flash'
              }
            })
            if (confirmMfaRedirect) {
              this.$router.push(`/home/${this.$route.query?.mfa_redirect}`)
            } else {
              this.$emit('close')
            }
          } else {
            this.$emit('close')
          }
        } else {
          this.$emit('close')
        }
      } else {
        this.$emit('close')
        this.$modal.show(TwoFactorAuthModal, {
          stepProps: this.step,
          onClickEnableAuthAppButton: from === 'sms' ? () => this.enableAuthApp(from) : null,
          onClickEnableSmsAuthButton: from === 'sms' ? null : () => this.enableSmsAuth(from),
          onClick: async () => {
            this.showConfirmModal(from)
          }
        }, {
          width: 600,
          height: from === 'sms' ? 444 : 483
        })
      }
    }
  }
}
</script>
<style lang="less">
.multi-factor-authentication {
  width: 100%;
  padding: 0 0 10px 0;
  .title-container {
    padding: 0 10px;
    display: flex;
    align-items: center;
    .icon {
      font-size: 18px;
      margin-right: 2px;
    }
  }
  .text {
    color: #748294;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.12px;
    margin: 10px 0;
  }
  .last-downloaded-date, .activated-date {
    color:  #97A7B7;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.12px;
  }
  .last-downloaded-date {
    margin-top: 10px
  }
  .activated-date{
    margin-top: 10px;
  }
  .explanation {
    border-bottom: 1px solid #E4E7EC;
    padding: 0 10px 10px 10px;
  }
  .mfa-config-section {
    padding: 0 10px;
    .authenticator-app {
      margin-bottom: 24px;
      .button-wrapper {
        display: flex;
        .backup-button {
          margin-right: 10px;
        }
      }
    }
    .sms-backup {
      .reveal {
        color: #0089C0;
        text-decoration-line: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.confirm-without-backup-modal {
  width: 500px;
}
.confirm-without-backup-modal, .confirm-removing-authentication {
  .modal-footer-container {
    .footer-actions {
      justify-content: space-between;
    }
  }
}
</style>
