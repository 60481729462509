<template lang="pug">
.input-sms-confirmation-code
  .fp-row
    .left
      .img-wrapper
        img(:src="require('@/core/assets/img/mfa/group.png')" :style="{width: '42px', height: '85.313px'}")
    .right
      .title {{ $t('profile.security.mfa.modal.enable_mfa.login_with_code') }}
      .text(v-html="$t('profile.security.mfa.modal.enable_mfa.login_with_code.input_label',[phoneNumber ? phoneNumber : this.$t('profile.security.mfa.sms_backup.text.phone_not_registered')]) ")
      fpui-input-text(
        :value="verificationCode"
        @input="value => sendCode(value)"
        :placeholder="'000 000'"
        autofocus
      )
</template>

<script>
export default {
  data () {
    return {
      verificationCode: ''
    }
  },
  computed: {
    user () {
      return this.$store.getters.SESSION
    },
    phoneNumber () {
      const phone = this.user.phone
      if (!phone) return ''
      return this.showPhoneNumber ? phone : `******${phone.slice(-4)}`
    }
  },
  methods: {
    sendCode (val) {
      this.verificationCode = val
      this.$emit('send-code', val)
    }
  }
}
</script>

<style lang="less">
.input-sms-confirmation-code {
  .fp-row {
    display: flex;
    align-items: center;
    margin: 16px 30px;
    .text {
      color: #748294;
      font-size: 12px;
      letter-spacing: -0.12px;
      margin: 8px 0;
    }
    .key {
      color: @text-color;
      font-size: 12px;
      letter-spacing: -0.12px;
      font-family: "Source Code Pro";
    }
    .left {
      display: flex;
      align-items: center;
      margin-right: 24px;
      .step {
        color: #00CCF9;
        font-size: 12px;
        font-weight: 600;
        border-radius: 86px;
        background: #C3F4FF;
        width: 24px;
        height: 24px;
        padding: 1px 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
      }
      .img-wrapper {
        border-radius: 5px;
        background: #F6F9FC;
        width: 195px;
        height: 104px;
        padding: 0px 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .qr-code-wrapper {
        display: flex;
        width: 195px;
        height: 195px;
        align-items: center;
        justify-content: center;
      }
    }
    .right {
      width: 100%;
    }
  }
}
</style>
